<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">机构老师</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">老师列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="searchForm.name"
                clearable
                size="small"
                placeholder=""
              ></el-input>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="handlAddOrUpdate()"
              >新增</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="姓名"
                align="center"
                prop="name"
                show-overflow-tooltip
              />
              <el-table-column
                label="手机号"
                align="center"
                prop="mobile"
                show-overflow-tooltip
              />
              <el-table-column
                label="身份证号"
                align="center"
                prop="idcard"
                show-overflow-tooltip
              />
              <el-table-column
                label="职位"
                align="center"
                prop="position"
                show-overflow-tooltip
              />
              <el-table-column
                label="专业"
                align="center"
                prop="major"
                show-overflow-tooltip
              />
              <el-table-column
                label="登录账号"
                align="center"
                prop="adminName"
                show-overflow-tooltip
              />
              <el-table-column label="操作" align="center" width="120px">
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="handlAddOrUpdate(scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="appraisalReset(scope.row)"
                      >重置密码</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      width="35%"
    >
      <el-form
        :model="ruleForm"
        style="width: 100%"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idcard">
          <el-input
            v-model="ruleForm.idcard"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            v-model="ruleForm.mobile"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-input
            v-model="ruleForm.position"
            placeholder="请输入职位"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业" prop="major">
          <el-input
            v-model="ruleForm.major"
            placeholder="请输入专业"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive, validatePhone, validateIdCard } from "@/utils/common";
import { IdentityCodeValid } from '@/utils/idcard.js';
export default {
  name: "appraisalInstitution/teacherManage",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
        searchForm:{
            name: "", //姓名
        },
        dialogFormVisible:false,
        dialogTitle:'',
        ruleForm: {
            name: "", //姓名
            idcard: "", //身份证号
            mobile: "", //手机号
            position: "", //职位
            major: "", //专业
        },
        rules:{
            name: [
                { required: true, trigger: "blur", message: "姓名不能为空" },
            ],
            idcard: [
                { required: true,validator: validateIdCard, trigger: 'blur' }
            ],
            mobile: [
                { required: true,validator: validatePhone, trigger: 'blur' }
            ],
        }
    };
  },
  created() {
  },
  computed: {},
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchForm.name) {
        params.name = this.searchForm.name;
      }
      this.doFetch({
        url: "/biz/evaluate/evaluateExamTeacher/evaluateExamTeacherList",
        params,
        pageNum,
      });
    },
    // 新增或编辑
    handlAddOrUpdate(row){
        this.emptyData()
        if (row) {
            this.ruleForm = JSON.parse(JSON.stringify(row))
            this.dialogTitle = "编辑老师";
            this.ruleForm.evaluateExamTeacherId = row.evaluateExamTeacherId; //id
        }else{
            this.dialogTitle = "新增老师";
            this.ruleForm.evaluateExamTeacherId = ''; //id
        }
        this.dialogFormVisible = true;
    },
    // 新增/编辑 - 确认
    doOk() {
        let url
        if(this.ruleForm.evaluateExamTeacherId){
            url = '/biz/evaluate/evaluateExamTeacher/updateEvaluateExamTeacher'
        }else{
            url = '/biz/evaluate/evaluateExamTeacher/addEvaluateExamTeacher'
        }
        this.$refs.ruleForm.validate((valid) => {
            if (valid) {
            this.$post(url, this.ruleForm)
                .then((res) => {
                if (res.status == 0) {
                    this.dialogFormVisible = false;
                    this.$message.success(res.message);
                    this.getData(-1);
                }
                })
                .catch((error) => {
                    console.log(error);
                });
            } else {
            return false;
            }
      });
    },
    /* 删除 */
    appraisalReset(row) {
      this.doDel({
        url: "/biz/evaluate/evaluateExamTeacher/restPassword",
        title:'重置密码',
        msg: `确认重置${row.adminName}密码？`,
        ps: {
          type: "post",
          data: { evaluateExamTeacherId:row.evaluateExamTeacherId },
        },
      });
    },
    // 清空数据
    emptyData(){
        this.ruleForm={
            name: "", //姓名
            idcard: "", //身份证号
            mobile: "", //手机号
            position: "", //职位
            major: "", //专业
        }
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    
  },
};
</script>
<style lang="less"></style>
